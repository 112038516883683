import React, { useEffect, useState } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import axios from "axios";
import { Link } from 'react-router-dom';
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import UserService from '../services/UserService';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import {Row, Col, Form} from 'react-bootstrap';
import { getRoles } from '@testing-library/react';

const serverConfig = require("../config/server.js");

const DataIngestionDetails = () => {

  const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
  const [appDataGrid, setAppDataGrid] = useState([]);
  const [appDataGridRender, setAppDataGridRender] = useState([]);
  const [uploadErrorList, setUploadErrorList] = useState([]);
  const [deleteModalShow, setdeleteModalShow] = useState(false);
  const [deleteInfoShow, setDeleteInfoShow] = useState(false);
  const [unauthorizedDeleteUser, setUnauthorizedDeleteUser] = useState(false);  
  const [deleteReason, setDeleteReason] = useState('');
  const [importId, setImportId] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [deleted, setDeleted] = useState('');
  const [date, setDate] = useState('');
  const [reason, setReason] = useState('')
  const [rolesList, setRolesList] = useState([]);


  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const data = {
    columns: [
      //{ label: 'Template ID', field: 'template_id', sort: true, key: 'template_id'},
      //{ label: 'Import ID', field: 'import_id', sort: true, key: 'import_id'},
      // { label: 'Start time', field: 'start_time', sort: true, key: 'start_time'},
      // { label: 'End time', field: 'end_time', sort: true, key: 'end_time'},
      //{ label: 'Last Update By', field: 'last_updated_by', sort: true, key: 'last_updated_by'},
      //{ label: 'Last Updated Time', field: 'last_updated_at', sort: true, key: 'last_updated_at'},
      { label: 'Template name', field: 'template_name', sort: true, key: 'template_name'},
      { label: 'File name', field: 'file_name', sort: true, key: 'file_name'},
      { label: 'Status', field: 'status', sort: true, key: 'status_hidden'},
      // { label: 'End time', field: 'end_time', sort: true, key: 'end_time'},
      // { label: 'Uploaded by', field: 'uploaded_by', sort: true, key: 'uploaded_by'},
      //{ label: 'Last Updated Time', field: 'last_updated_at', sort: true, key: 'last_updated_at'},
      //{ label: 'Last Update By', field: 'last_updated_by', sort: true, key: 'last_updated_by'},
      { label: 'Uploaded by', field: 'uploaded_by', sort: true, key: 'uploaded_by'},
      { label: 'Uploaded Month', field: 'uploaded_month', sort: true, key: 'uploaded_month'},
      { label: 'Uploaded time', field: 'start_time', sort: true, key: 'start_time'},
      { label: 'Upload Comments', field: 'comments', sort: true, key: 'comments'},
      { label: 'Actions', field: 'actions', sort: false, key: 'roles' },
    ],
    rows: appDataGridRender,
  };

  const [showErrors, setShowErrors] = useState(false);
  const handleCloseErrors = () => setShowErrors(false);
  const handleShowErrors = () => setShowErrors(true);

  const handleClick = (importid) => {
    console.log(importid);
    //import_get_status
    axios.post(serverConfig.api_base_url + "import_get_status",{token:cToken, importId:importid})
    .then(response=>{
      console.log('import_get_status response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          console.log(JSON.parse(response.data.items));
          setUploadErrorList(JSON.parse(response.data.items));
          handleShowErrors();
        } else if(response.data.items === null && response.data.message === 'success') {
          setUploadErrorList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setCToken(response.data.token);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleReasonChange = (event) => {
    setDeleteReason(event.target.value);
  }

  const handleClickDelete = (import_id) => {
    axios.post(serverConfig.api_base_url + "import_delete_status",{
      token: cToken,
      importId: import_id,
    })
    .then(response=>{
      
      setDeleteInfoShow(true);
      const res = JSON.parse(response.data.items);
      console.log(JSON.parse(response.data.items));
      console.log(res[0]);
      setDate(res[0].date);
      setReason(res[0].reason);
      setDeleted(res[0].deleted);
      // if(response.data.status === 200) {
      //   setdeleteModalShow(false)
      // }

    }).catch((err) => {
      console.log(err);
      alert('Some error occured');
    })
  }

  const handleDeleteInfo = (import_id, template_id) => {

    axios.post(serverConfig.api_base_url + "user_check_privilege",{token:cToken, privilege:'import_delete'})
    .then(response=>{
      //console.log('import_delete privilege response:', JSON.parse(response));
      if(response.data.status === 200){
        if(response.data.is_privileged === 0){
          setUnauthorizedDeleteUser(true);
        } else {
          setImportId(import_id);
          setTemplateId(template_id);
          setdeleteModalShow(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleDelete = () => {
    axios.post(serverConfig.api_base_url + "import_delete",{
      token: cToken,
      importId: importId,
      templateId: templateId,
      deleteReason: deleteReason
    })
    .then(response=>{
      
      console.log(response.data.items);
      if(response.data.status === 200) {
        setdeleteModalShow(false)
      }

    }).catch((err) => {
      console.log(err);
      alert('Some error occured');
    })
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setCToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    axios.post(serverConfig.api_base_url + "import_get_list",{token:cToken})
    .then(response=>{
      console.log('import_get_list response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          console.log(JSON.parse(response.data.items));
          let items = JSON.parse(response.data.items);
          setAppDataGrid(JSON.parse(response.data.items));

          setAppDataGrid((stateAppDataGrid) => {
            let appDataArray = stateAppDataGrid;
            let appData = [];

            console.log(appDataArray);
            //appDataArray.sort((a, b) => b.last_updated.localeCompare(a.last_updated)).map((item, index) => {
            appDataArray && appDataArray.reverse().map((item, index) => {
              let importId = item.import_id;
              if(item.status == 'success'){
                item.status=[<span className='txt-green'>{item.status}</span>];
                {item.actions = (<>
                    <Button variant='text' title="Delete Record" onClick={() => handleDeleteInfo(item?.import_id, item?.template_id)}><img src={require('./../assets/images/delete-icon.png')} alt="Delete" className='me-2' /></Button></>)}
              } else if(item.status == 'failed'){
                item.status=[<span className='txt-red'>{item.status}</span>];
                item.clickEvent= () => handleClick(importId);
                item.rowClassNames= 'clickable';
              } else if(item.status == 'initiated'){
                item.status=[<span className='txt-blue'>{item.status}</span>];
              } else if(item.status == 'submitted'){
                item.status=[<span className='txt-blue'>{item.status}</span>];
              } else if(item.status == 'in_progress'){
                item.status=[<span className='txt-yellow'>{item.status}</span>];
              } 
              else if(item.status == 'deleted'){
                item.status=[<span className='txt-red'>{item.status}</span>];
                item.clickEvent= () => handleClickDelete(importId);
                item.rowClassNames= 'clickable';
              }
              appData.push(item);

              // items.reverse().map((item, index) => {
              //   let curRole = [];
              //   curRole = item.roles;
              //   //console.log(curRole);
              //   //console.log(Array.of(curRole));
              //    {item.actions = (<>
              //     <Button variant='text' title="Delete User" onClick={() => handleDeleteInfo(item?.import_id, item?.template_id)}><img src={require('./../assets/images/delete-icon.png')} alt="Delete" className='me-2' /></Button></>)}
    
              //     {appData.push(item)}
              // }); 
            });
            setAppDataGridRender(appData);
            return stateAppDataGrid;
          })
          appDataGrid.map((item, index) => {
            if(item.status == 'Success'){
              item.status=[<span className='txt-green'>{item.status}</span>];
            } else if(item.status == 'Failed'){
              item.status=[<span className='txt-red'>{item.status}</span>];
              item.clickEvent= () => handleClick(item.category);
              item.rowClassNames= 'clickable';
            }
          });
          console.log(appDataGrid);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  },[deleteModalShow]);

return (<>
    <Header />
    <Modal show={deleteModalShow} onHide={() => setdeleteModalShow(false)} backdrop="static"keyboard={false} centered className='modal-medium'>
                  <Form>
                    <Modal.Header closeButton>
                      <Modal.Title>Delete Record</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* <p>Edit roles for {currentUser}</p> */}
                      <Row>
                      <Form.Group controlId="deleteData" className="delete-data mb-3 mt-1">
                          <Form.Label>
                           Reason for delete : 
                          </Form.Label>
                          <Form.Control onChange={handleReasonChange} name="file" type="text" required />
                        </Form.Group>

                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="transparent" onClick={() => setdeleteModalShow(false)}>Cancel</Button>
                      <Button variant="primary" onClick={handleDelete} className='ml-3'>Delete</Button>
                    </Modal.Footer>
                  </Form>
    </Modal>

    <Modal show={unauthorizedDeleteUser} onHide={() => unauthorizedDeleteUser(false)} backdrop="static"keyboard={false} centered className='modal-medium'>
                  <Form>
                    <Modal.Header>
                      <Modal.Title>Unauthorized User for Delete Operation</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                      <Button variant="transparent" onClick={() => setUnauthorizedDeleteUser(false)}>Ok</Button>
                    </Modal.Footer>
                  </Form>
    </Modal>

    <Modal show={deleteInfoShow} onHide={() => setDeleteInfoShow(false)} backdrop="static"keyboard={false} centered className='modal-medium'>
                  <Form>
                    <Modal.Header closeButton>
                      <Modal.Title>Delete Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="delete-info">
                      {/* <p>Edit roles for {currentUser}</p> */}
                      <Row>
                      
                      Deleted by : {deleted}  <br></br>
                      Date :  {date}   <br></br>
                      Reason :  {reason}    <br></br>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                  </Form>
    </Modal>
    <div className='main-wrapper'>
      <aside className="sidebar-component"><Sidebar token={cToken} handleLogout={handleLogout} /></aside>
      <main>
        <div className='content'>
          <div className='content-wrapper align-items-start'>
            <Container fluid>
              <div className='page-title'>
                <h1>Data Ingestion Details</h1>
                <Link to="/data-ingestion-details/data-ingestion" className='btn btn-transparent'>Import New Data</Link>
              </div>
              <div className='d-inline-block position-absolute txt-blue'> 
                <CSVLink data={data.rows} headers={data.columns} filename="import-log.csv"><u><b>Download Import Log as CSV</b></u></CSVLink>
              </div>
              <div className="datatable">
                <MDBDataTable striped borderless data={data} entries={10} entriesOptions={[ 10, 20, 30 ]} info={false} />
                <Modal show={showErrors} onHide={handleCloseErrors} centered backdrop="static" keyboard={false} className='modal-errors errors-wrapper'>
                  <Modal.Header closeButton>
                    <Modal.Title><i className='txt-red'>*{uploadErrorList.length}{uploadErrorList.length >= 50 ? '+' : null} errors found, please correct and re-upload the file</i></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='error-content'>
                      <ul>
                        {uploadErrorList.length > 0 && uploadErrorList.map((item, index) => {
                          return(
                            <li key={index}>
                              <span>{index+1} :</span>
                              <p><i>{item.error}</i></p>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </Container>
          </div>
        </div>
      </main>
    </div>
  </>);
}

export default DataIngestionDetails;