import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import axios from "axios";
import UserService from '../services/UserService';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const serverConfig = require("../config/server.js");

const DataIngestion = () => {

  const [cToken, setCToken] = useState(sessionStorage.getItem('token'));

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const [isLoading, setIsLoading] = useState(false);

  const [DIFormInputs, setDIFormInputs] = useState({});
  const [DIFormDisabled, setDIFormDisabled] = useState(false);

  const [filedata, setFiledata] = useState(null);
  const [dataCollectionDate, setDataCollectionDate] = useState(null);
  const [userComments, setUserComments] = useState('');

  const [uploadErrorList, setUploadErrorList] = useState([]);
  const [importId, setImportId] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  
  const [templateGroup, setTemplateGroup] = useState([]);
  const [template, setTemplate] = useState(false);
  const [templateInstructions, setTemplateInstructions] = useState(null);
  const [templateParameter, setTemplateParameter] = useState([]);
  const [templateFileData, setTemplateFileData] = useState({});
  const [templateId, setTemplateId] = useState(null);

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setCToken(response.data.token);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setCToken(sessionStorage.getItem('token'));
    }
  }, []);
  
  useEffect(() => {
    //getImportStatus();
    if(importId !== null && uploadStatus !== null){
      let interval;
      console.log(uploadStatus);
      if(uploadStatus == 'success' || uploadStatus == 'failed'){
        clearInterval(interval);
      } else {
        interval = setInterval(function() {
          getImportStatus();
        }, 5000);
      }
    }
  }, [importId,uploadStatus]);

  const getImportStatus = () => {
    console.log('importId', importId);
    console.log('uploadStatus', uploadStatus);
    //import_get_status
    axios.post(serverConfig.api_base_url + "import_get_status",{token:cToken, importId:importId})
    .then(response=>{
      console.log('import_get_status response', response);
      if(response.data.status === 200){
        setUploadStatus(response.data.message);
        if(response.data.items !== null && response.data.message === 'failed'){
          console.log(JSON.parse(response.data.items));
          setUploadErrorList(JSON.parse(response.data.items));
          setDIFormDisabled(true);
          handleShowUploadFail(true);
        } else if(response.data.items === null && response.data.message === 'success') {
          setUploadErrorList([]);
          setDIFormDisabled(false);
          handleShowUploadSuccess(true);
          resetDIFormInputs();
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    axios.post(serverConfig.api_base_url + "template_get_group",{token:cToken, parentGroupId:null})
    .then(response=>{
      console.log('template_get_group response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          let respItems = JSON.parse(response.data.items);
          console.log(respItems);
          console.log(respItems[0].id);
          if(respItems.id !== null){
            axios.post(serverConfig.api_base_url + "template_get_group",{token:cToken, parentGroupId:respItems[0].id})
            .then(response=>{
              console.log('template_get_group response', response);
              if(response.data.status === 200){
                if(response.data.items !== null){
                  console.log(JSON.parse(response.data.items));
                  var newArr = respItems.map(object => {
                    return {...object, options: JSON.parse(response.data.items)};
                  });
                  console.log(newArr);
                  setTemplateGroup(newArr);
                }
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                alert(response.data.message);
              }
            })
            .catch(err=>console.log("error is",err));
          }
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }, [cToken]);

  const handleSelect = (event) => {
    //templateGroup.splice(1);
    let arrIndex = event.target.getAttribute('data-index');
    console.log(arrIndex);
    templateGroup.splice(arrIndex+1);
    let selectedId = event.target.childNodes[event.target.selectedIndex].getAttribute('data-id');
    console.log(selectedId);
    //document.getElementById(selectedId).value = "";
    console.log(event.target.childNodes[event.target.selectedIndex].getAttribute('data-childgrptext'));
    console.log(event.target.id, event.target.value);
    console.log(templateGroup);

    //console.log(templateGroup.filter((x) => x.id == event.target.id));
    templateGroup.find(obj => {
      console.log(obj);
      console.log(obj.options);
      const arr = obj.options.filter((x) => x.id == selectedId);

      axios.post(serverConfig.api_base_url + "template_get_group",{token:cToken, parentGroupId:selectedId})
      .then(response=>{
        console.log('template_get_group response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            console.log(JSON.parse(response.data.items));

            var newArr = arr.map(object => {
              return {...object, options: JSON.parse(response.data.items)};
            });
            console.log(newArr);
            console.log([...templateGroup, ...newArr]);
            setTemplateGroup([...templateGroup, ...newArr]);
            setTimeout(() => {
              if(document.getElementById(selectedId)){
                document.getElementById(selectedId).value = "";
                setDIFormInputs({});
              }
            });

            console.log(arr);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      console.log(arr, arr.length);
      if(arr.length > 0){
        console.log(arr[0].template_id);
        setTemplateId(arr[0].template_id);
        if(arr[0].template_id !== null){
          setTemplate(true);

          //template_get_instructions
          axios.post(serverConfig.api_base_url + "template_get_instructions",{token:cToken, templateId:arr[0].template_id})
          .then(response=>{
            console.log('template_get_instructions response', response);
            if(response.data.status === 200){
              if(response.data.item !== null){
                console.log(response.data.item);
                setTemplateInstructions(response.data.item);
              } else {
                setTemplateInstructions(null);
              }
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));

          //template_get_parameter
          axios.post(serverConfig.api_base_url + "template_get_parameter",{token:cToken, templateId:arr[0].template_id})
          .then(response=>{
            console.log('template_get_parameter response', response);
            if(response.data.status === 200){
              if(response.data.items !== null){
                console.log(JSON.parse(response.data.items));
                setTemplateParameter(JSON.parse(response.data.items));
              } else {
                setTemplateParameter([]);
              }
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));
          
          //template_get_file
          axios.post(serverConfig.api_base_url + "template_get_file",{token:cToken, templateId:arr[0].template_id})
          .then(response=>{
            console.log('template_get_file response', response);
            if(response.data.status === 200){
              if(response.data.item !== null){
                console.log(JSON.parse(response.data.item));
                setTemplateFileData(JSON.parse(response.data.item));
              } else {
                setTemplateFileData({});
              }
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));
        } else {
          setTemplate(false);
        }
      } else {
        setTemplate(false);
      }
    });
  }

  const handleUserComments = (event) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;
    setUserComments(value);
  }

  const handleChangeDIFormInputs = (event) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;

    if(event.target.type === 'file'){
      var extension = value.split('.').pop();

      if(extension=='xls' || extension=='csv' || extension=='xlsx'){
        console.log("File type matched");
        var file = event.target.files[0];
        console.log(file);
        if(file !== undefined) {
          console.log(file);
          setFiledata(file);
          setDIFormInputs(values => ({...values, [name]: file.name}));
        }
      } else {
        alert("Supports only xls, csv, xlsx document format.");
        event.target.value = null;
      }
    } else if(event.target.type === 'date'){
      setDataCollectionDate(value);
    }
  }

  const submitDataIngestionForm = (event) => {
    event.preventDefault();
    console.log(DIFormInputs);
    console.log(filedata);
    console.log(dataCollectionDate);
    console.log(userComments);
    
    axios.post(serverConfig.api_base_url + "import_initiate",{token:cToken, template_id:templateId, file_name: DIFormInputs.file, comments:userComments})
    .then(async (response) => {
      console.log('import_initiate response', response);
      if(response.data.status === 200){
        setImportId(response.data.import_id);
        const formData = new FormData();
        formData.append('file', filedata);
        formData.append('import_id', response.data.import_id);
        formData.append('date', dataCollectionDate);
        formData.append('comments', userComments);
        console.log(formData);
        setIsLoading(true);
        const resp = await axios.post(serverConfig.fapi_base_url+"file_upload", formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${cToken}`,
          },
        }).then((res) => {
          console.log(res);
          //if(res.data['File upload success']){
            //import_get_status
            axios.post(serverConfig.api_base_url + "import_get_status",{token:cToken, importId:response.data.import_id})
            .then(response=>{
              console.log('import_get_status response', response);
              if(response.data.status === 200){
                setUploadStatus(response.data.message);
                if(response.data.items !== null && response.data.message === 'failed'){
                  console.log(JSON.parse(response.data.items));
                  setUploadErrorList(JSON.parse(response.data.items));
                  setDIFormDisabled(true);
                  handleShowUploadFail(true);
                  setIsLoading(false);
                } else if(response.data.items === null && response.data.message === 'success') {
                  setUploadErrorList([]);
                  setDIFormDisabled(false);
                  handleShowUploadSuccess(true);
                  resetDIFormInputs();
                  setIsLoading(false);
                }
              } else if(response.data.status === 301){
                handleLogout();
                setIsLoading(false);
              } else {
                alert(response.data.message);
                setIsLoading(false);
              }
            })
            .catch(err=>console.log("error is",err));
          /*  }else {
            setUploadErrorList(res.data);
            console.log(res.data);
            setDIFormDisabled(true);
            handleShowUploadFail(true);
            setIsLoading(false);
          } */
        })
        //.catch(err=>console.log("error is",err));
        .catch(err=>{
          console.log("error is",err);
          setIsLoading(false);
          alert("Server error occured. Please try after sometime.")
        });
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const resetDIFormInputs = () => {
    document.getElementById("uploadData").value = "";
    templateGroup.splice(1);
    document.getElementById('1').value = "";
    setTemplate(false);
    setTemplateInstructions(null);
    setTemplateParameter([]);
    setTemplateFileData({});
    setDIFormInputs({});
    setFiledata(null);
    setDataCollectionDate(null);
    setUserComments(null);
    setUploadErrorList([]);
  }

  const backToDIForm = () => {
    //resetDIFormInputs();
    setDIFormDisabled(false);
  }

  //modal success
  const [showUploadSuccess, setShowUploadSuccess] = useState(false);
  const handleCloseUploadSuccess = () => setShowUploadSuccess(false);
  const handleShowUploadSuccess = () => setShowUploadSuccess(true);
  //modal Fail
  const [showUploadFail, setShowUploadFail] = useState(false);
  const handleCloseUploadFail = () => setShowUploadFail(false);
  const handleShowUploadFail = () => setShowUploadFail(true);

  return (<>
    <Header />
    <div className='main-wrapper'>
      {isLoading && (<div id='spinner'></div>)}
      <aside className="sidebar-component"><Sidebar token={cToken} handleLogout={handleLogout} /></aside>
      <main>
        <div className='content'>
          <div className='content-wrapper'>
            <Container fluid>
              <Row>
                <Col className='pe-5'>
                  <Form onSubmit={submitDataIngestionForm}>
                    <fieldset disabled={DIFormDisabled}>
                      <div className='page-title'><h1>Data Ingestion</h1></div>
                      <p className='mb-3 txt-blue'><i>Select the type of file to upload in order to view the template for that data.</i></p>
                      {templateGroup.map((item,index) => {
                        return(
                          <div key={index} className='custom-select mb-2'>
                            <Form.Select data-index={index} id={item.id} name={"select_"+item.id} onChange={handleSelect} defaultValue="" required>
                              <option value="">{item.child_group_display_text}</option>
                              {item.options.map((optitem, optindex) => {
                                return(
                                  <option key={optindex} data-id={optitem.id} data-childgrptext={optitem.child_group_display_text} value={optitem.name}>{optitem.display_name}</option>
                                )
                              })}
                            </Form.Select>
                          </div>
                        )
                      })}
                      {template && <>
                        {Object.keys(templateFileData).length > 0 && <div className='txt-gray'>
                          {templateFileData.format === 'csv' ? <a href={'data:@file/csv;base64,'+ templateFileData.data} download={templateFileData.name+".csv"} className="text-decoration-underline" title={templateFileData.name}>VIEW TEMPLATE</a> : <a href={'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ templateFileData.data} download={templateFileData.name} className="text-decoration-underline" title={templateFileData.name}>VIEW TEMPLATE</a>}
                        </div>}
                        <Form.Group controlId="uploadData" className="upload-data mb-3 mt-1">
                          <Form.Label>
                            {DIFormInputs.file ? DIFormInputs.file : <><img src={require('./../assets/images/upload-icon-big.png')} alt="Upload Data" className="mb-3" /><br/>
                            <i>Drag &amp; Drop or click here to <b>BROWSE</b></i></>}
                          </Form.Label>
                          <Form.Control name="file" type="file" accept={templateFileData.format === 'csv' ? ".csv" : ".xls, .xlsx"} filename={DIFormInputs.file || ''} onChange={handleChangeDIFormInputs} required />
                        </Form.Group>

                        <Form.Group controlId="UserComments" className="txt-gray">
                          <Form.Label><b>User Comments:</b><span className='txt-gray'>*</span></Form.Label>
                          <Form.Control onChange={handleUserComments} name="userComments" type="text" required />
                        </Form.Group>

                        {templateParameter.map((item, index) => {
                          return(
                            <Form.Group className='mb-3' key={index}>
                              <Form.Label>{item.display_text}: <span className='txt-red'>*</span></Form.Label>
                              <Form.Control type={item.name} required onChange={handleChangeDIFormInputs} name="dataCollectionDate"></Form.Control>
                              <Form.Text className="text-muted">{item.instruction}</Form.Text>
                            </Form.Group>
                          )
                        })}
                        
                        {/* <p className={fileSizeError ? 'conditions-txt error-txt' : 'conditions-txt txt-blue'}><i><span>*</span>{fileSizeError ? fileSizeError : 'Supports only xls, csv, xlsx document format.'}</i></p> */}
                        {templateInstructions && <div className='txt-gray'>
                          <p className='mt-4'><i><span className='me-3'>*</span>KEY INSTRUCTIONS WHILE UPLOADING FILE:</i></p>
                          <div dangerouslySetInnerHTML={{__html: templateInstructions}}></div>
                          {/* <ul>
                            <li><i>Please upload the data only in the format provided in the template.</i></li>
                            <li><i>There should not be multiple tabs in a single sheet.</i></li>
                            <li><i>Only .xls files are allowed to be uploaded</i></li>
                          </ul> */}
                        </div>}
                      </>}
                      <Row className='mt-4'>
                        <Col><Button variant="main" className='btn-full' type="submit">SUBMIT</Button></Col>
                        <Col><Button variant="transparent" className='btn-full' type="button" onClick={resetDIFormInputs}>RESET</Button></Col>
                      </Row>
                    </fieldset>
                  </Form>
                </Col>
                <Col className='ps-5'>
                  {DIFormDisabled ?
                    <>
                      <div className='errors-wrapper'>
                        <div className='h4 mb-4'><i className='txt-red'>*{uploadErrorList.length}{uploadErrorList.length >= 50 ? '+' : null} errors found, please correct and re-upload the file</i></div>
                        <div className='error-content'>
                          <ul>
                            {uploadErrorList.length > 0 && uploadErrorList.map((item, index) => {
                              return(
                                <li key={index}>
                                  <span>{index+1} :</span>
                                  <p><i>{item.error}</i></p>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className='text-center mt-4'>
                        <Button variant="text" onClick={backToDIForm}>Back</Button>
                      </div>
                    </> : <img src={require('./../assets/images/upload-image-right.png')} alt="Upload image" className='img-fluid' style={{position: 'relative', top: '50%', transform: 'translateY(-50%)'}} />
                  }
                  
                </Col>
              </Row>
              {/* Modal Success */}
              <Modal show={showUploadSuccess} onHide={handleCloseUploadSuccess} backdrop="static" keyboard={false} centered className="modal-status">
                <Modal.Body>
                  <img src={require('./../assets/images/success-icon.png')} alt="Success" />
                  <h3 className='txt-green'>Success!</h3>
                  <p>Your file has been uploaded successfully.</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="text" onClick={handleCloseUploadSuccess}>
                    Back
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Modal Fail */}
              <Modal show={showUploadFail} onHide={handleCloseUploadFail} backdrop="static" keyboard={false} centered className="modal-status">
                <Modal.Body>
                  <img src={require('./../assets/images/fail-icon.png')} alt="Fail" />
                  <h3 className='txt-red'>Failed!</h3>
                  <p>Your file uploading is failed due to multiple errors <br/>please check and upload again.</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="text" onClick={handleCloseUploadFail}>
                    Back
                  </Button>
                </Modal.Footer>
              </Modal>
            </Container>
          </div>
        </div>
      </main>
    </div>
  </>);
}

export default DataIngestion;

