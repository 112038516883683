import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Button, Form, Modal} from 'react-bootstrap';
import axios from "axios";
import { Link } from 'react-router-dom';
import { MDBDataTable } from "mdbreact";
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import UserService from '../services/UserService';
import { useNavigate } from "react-router-dom";

const serverConfig = require("../config/server.js");

const Users = () => {

  const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
  const [appDataGridRender, setAppDataGridRender] = useState();
  const [actions, setActions] = useState(false);

  const [userPrivileged, setUserPrivileged] = useState(false);
  const [inviteDisabled, setInviteDisabled] = useState(true);

  const navigate = useNavigate();

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setCToken(response.data.token);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    }
  }, []);

  useEffect(() => {
    if(cToken !== null && cToken !== undefined){
      /* user_check_privilege - user_list */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:cToken, privilege:'user_list'})
      .then(response=>{
        console.log("user_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setUserPrivileged(false);
            navigate("/");
          } else if(response.data.is_privileged === 1){
            setUserPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
      /* user_check_privilege - user_create */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:cToken, privilege:'user_create'})
      .then(response=>{
        console.log("user_create privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setInviteDisabled(true);
          } else if(response.data.is_privileged === 1){
            setInviteDisabled(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [cToken]);

  useEffect(() => {
    console.log('cToken', cToken);
    console.log(sessionStorage.getItem('token'));
    console.log('userPrivileged', userPrivileged);
    
    //if(cToken !== null && cToken !== undefined && userPrivileged){
    if(userPrivileged) {
      if(cToken !== null && cToken !== undefined){
        console.log('cToken', cToken);
        /* user_get_list */
        axios.post(serverConfig.api_base_url + "user_get_list",{token:cToken})
        .then(response=>{
          console.log("user_get_list response:", response);
          if(response.data.status === 200){
            let items = JSON.parse(response.data.items);
            //let items = [ {name: 'noam', roles: ['admin'], active: 1, locked: 0, email_id: 'noam.rosario@dbtez.com'}, {name: 'terence', roles: ['admin', 'test'], active: 1, locked: 0, email_id: 'terence.monteiro@amberoon.com'} ]
            console.log(items);
            //setAppDataGridRender(items);
            if (items !== null || items !== undefined){
              let appData = [];
              items.reverse().map((item, index) => {
                let curRole = [];
                curRole = item.roles;
                //console.log(curRole);
                //console.log(Array.of(curRole));
                return (<>
                  {item.active = item.active === 0 ? (<><button className='btn-icon me-2' title='Enable user' onClick={() => handleActive(item.email_id, 1)}><img src={require('./../assets/images/user-disabled-icon.png')} alt="Disabled" /></button> Disabled</>) : (<><button className='btn-icon me-2' title='Disable user' onClick={() => handleActive(item.email_id, 0)}><img src={require('./../assets/images/user-enabled-icon.png')} alt="Enabled" /></button> Enabled</>)}

                  {item.locked = item.locked === 0 ? (<><button className='btn-icon me-2' title='Lock user' onClick={() => handleLocked(item.email_id, 1)}><img src={require('./../assets/images/unlocked-icon.png')} alt="Unlocked" /></button> Unlocked</>) : (<><button className='btn-icon me-2' title='Unlock user' onClick={() => handleLocked(item.email_id, 0)}><img src={require('./../assets/images/locked-icon.png')} alt="Locked" /></button> Locked</>)}

                  {item.roles = [<div key={index} className='roles-col'><ul> { curRole && curRole.map((itemRole, indexRole) => <li key={indexRole}>{ itemRole }</li>) }</ul><button className='btn-icon ms-2 mt-1' title='Edit user role' onClick={() => handleRolesModal(item.name, item.email_id, curRole)}><img src={require('./../assets/images/edit-icon.png')} alt="Edit" /></button></div>]}

                  {item.actions = (<>
                  <Link to={`/user-management/edit-user?${item.email_id}`} title="Edit User"><img src={require('./../assets/images/edit-icon.png')} alt="Edit" className='me-2' /> Edit</Link></>)}

                  {appData.push(item)}
                </>);
              });
              console.log(appData);
              setAppDataGridRender(appData);
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));

        /* role_get_list */
        axios.post(serverConfig.api_base_url + "role_get_list",{token: cToken})
        .then(response=>{
          console.log('role_get_list response', JSON.parse(response.data.items));
          if(response.data.status === 200){
            setRolesList(JSON.parse(response.data.items));
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }
    }
  }, [cToken, actions, userPrivileged]);

  const data = {
    columns: [
      { label: 'Name', field: 'name', sort: true, key: 'name' },
      { label: 'Email Id', field: 'email_id', sort: false, key: 'email_id' },
      { label: 'Active', field: 'active', sort: false, key: 'active' },
      { label: 'Locked', field: 'locked', sort: false, key: 'locked' },
      { label: 'Roles', field: 'roles', sort: false, key: 'roles' },
      { label: 'Actions', field: 'actions', sort: false, key: 'roles' },
    ],
    rows: appDataGridRender,
  };

  const [rolesList, setRolesList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserEmail, setCurrentUserEmail] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [rolesInputs, setRolesInputs] = useState([]);
  const [curUserRole, setCurUserRole] = useState([]);

  const [rolesModalShow, setRolesModalShow] = useState(false);
  const handleCloseRolesModal = () => {
    setRolesInputs([]);//clear roles checkbox on close of modal
    setCurUserRole([]);
    setCurrentUser(null);
    setCurrentUserEmail(null);
    setCurrentUserRole(null);
    setClicked(false);
    setRolesModalShow(false);
  }

  const handleRolesModal = (username, useremail, roles) => {
    console.log(username, useremail, roles);
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setCurrentUserRole(roles);
    setRolesModalShow(true);
  }

  const [clicked, setClicked] = useState(false);
  const handleCheckboxChange = (event) => {
    const roleId = event.target.id;
    //console.log('clicked', clicked);
    if(!clicked){
      if(currentUserRole !== null && currentUserRole.length){
        setClicked(true);
        setRolesInputs(currentUserRole);
      }
    }
    if (event.target.checked) {
      setRolesInputs(values => ([...values, roleId ]));
    } else {
      if(clicked){
        setRolesInputs(rolesInputs.filter(e => e !== roleId));
      } else {
        setRolesInputs(currentUserRole && currentUserRole.filter(e => e !== roleId));
      }
    }
  }

  const handleApplyRole = () => {
    //console.log(roles);
    console.log('rolesInputs:', rolesInputs);
    console.log('CurrentUserRole:', currentUserRole);
    var grantList = [];
    var revokeList = [];
    // compare currentUserRole and rolesInputs
    for(var i = 0; i < rolesList.length; ++i) {
      let role = rolesList[i];
      if (rolesInputs.includes(role) && (currentUserRole === null || !currentUserRole.includes(role))) {
        grantList.push(role);
      } else if (currentUserRole != null && currentUserRole.includes(role) && !rolesInputs.includes(role)) {
        revokeList.push(role);
      }
    }
    console.log('grant list: ', grantList);
    console.log('revoke list:', revokeList);
    var changed = false;
    
    if(grantList.length){
      axios.post(serverConfig.api_base_url + "user_grant_role",{token: cToken, email_id: currentUserEmail, roles: grantList})
      .then(response=>{
        console.log('user_grant_role response', response);
        if(response.data.status === 200){
          console.log('success');
          if (!changed) {
            changed=true;
            setActions(!actions);// to refresh user list after role is updated
          }
          handleCloseRolesModal();
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }

    if (revokeList.length) {
      axios.post(serverConfig.api_base_url + "user_revoke_role",{token: cToken, email_id: currentUserEmail, roles: revokeList})
      .then(response=>{
        console.log('user_revoke_role response', response);
        if(response.data.status === 200){
          console.log('success');
          if (!changed) {
            changed=true;
            setActions(!actions);// to refresh user list after role is updated
          }
          handleCloseRolesModal();
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const handleActive = (emailid, changeActive) => {
    console.log(emailid);
    console.log(changeActive);

    axios.post(serverConfig.api_base_url + "user_active",{token: cToken, email_id: emailid, active: changeActive})
    .then(response=>{
      console.log('user_active response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user active is set 0/1
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLocked = (emailid, changeLock) => {
    console.log(emailid);
    console.log(changeLock);

    axios.post(serverConfig.api_base_url + "user_locked",{token: cToken, email_id: emailid, locked: changeLock})
    .then(response=>{
      console.log('user_locked response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user is locked / unlocked
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [createUserModalShow, setCreateUserModalShow] = useState(false);
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleCreateUser = (e) => {
    e.preventDefault();
    var formdata = {
      token:cToken, ...inputs
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "user_create",formdata)
    .then(response=>{
      console.log("User create response:", response);
      if(response.data.status === 200){
        setActions(!actions);// to refresh user list after user is created
        setCreateUserModalShow(false);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>
      <Header />
      <div className='main-wrapper'>
        <aside className="sidebar-component"><Sidebar token={cToken} handleLogout={handleLogout} /></aside>
        <main>
          <div className='content'>
            <div className='content-wrapper align-items-start'>
              <Container fluid>
                <div className='page-title'>
                  <h1>User Management</h1>
                  <Button variant="transparent" onClick={() => navigate("/user-management/create-user")} disabled={inviteDisabled}>Create New User</Button>
                </div>
                {/* <div className='page-heading'>
                  <h1>Users</h1>
                  <Button variant="transparent" className='btn-txt-black' onClick={() => setCreateUserModalShow(true)}>Create</Button>
                  <Modal show={createUserModalShow} onHide={() => setCreateUserModalShow(false)} backdrop="static"keyboard={false} centered>
                    <Form onSubmit={handleCreateUser}>
                      <Modal.Header closeButton>
                        <Modal.Title>Create User</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder="First Name" name="first_name" value={inputs.first_name || ""} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder="Last Name" name="last_name" value={inputs.last_name || ""} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Control type="email" placeholder="Email Id" name="email_id" value={inputs.email_id || ""} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder="Password" name="password" value={inputs.password || ""} onChange={handleChange} required />
                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="transparent" onClick={() => setCreateUserModalShow(false)}>Cancel</Button>
                        <Button variant="main rounded" type="submit" className='ms-3'><i className="fas fa-user-plus me-2"></i>Create</Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                </div> */}

                {/* User list table */}
                <section>
                  <div className='datatable datatable-users'>
                    <MDBDataTable striped borderless data={data} entries={5} searchLabel="Type here" />
                  </div>
                </section>

                {/* Edit user role */}
                <Modal show={rolesModalShow} onHide={handleCloseRolesModal} backdrop="static"keyboard={false} centered className='modal-medium'>
                  <Form>
                    <Modal.Header closeButton>
                      <Modal.Title>Edit User Role</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>Edit roles for {currentUser}</p>
                      <Row>
                        {rolesList.map((item, index) => (
                          <Col key={index}><Form.Check type="checkbox" id={item} value={item} label={item} defaultChecked={currentUserRole && currentUserRole.includes(item)} onChange={handleCheckboxChange} /></Col>
                        ))}
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="transparent" onClick={handleCloseRolesModal}>Cancel</Button>
                      <Button variant="primary" onClick={handleApplyRole} className='ml-3'>Apply</Button>
                    </Modal.Footer>
                  </Form>
                </Modal>
              </Container>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Users;
