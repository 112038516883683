import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/styles.css';
import RenderOnLogin from './RenderOnLogin';
import Dashboard from '../pages/Dashboard';
import Reports from '../pages/Reports';
import DataIngestionDetails from '../pages/DataIngestionDetails';
import DataIngestion from '../pages/DataIngestion';
import Users from '../pages/Users';
import InviteUser from '../pages/InviteUser';
import EditUser from '../pages/EditUser';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RenderOnLogin><Dashboard /></RenderOnLogin>} />
        <Route path="/reports" element={<RenderOnLogin><Reports /></RenderOnLogin>} />
        <Route path="/data-ingestion-details" element={<RenderOnLogin><DataIngestionDetails /></RenderOnLogin>} />
        <Route path="/data-ingestion-details/data-ingestion" element={<RenderOnLogin><DataIngestion /></RenderOnLogin>} />
        <Route path="/user-management" element={<RenderOnLogin><Users /></RenderOnLogin>} />
        <Route path="/user-management/create-user" element={<RenderOnLogin><InviteUser /></RenderOnLogin>} />
        <Route path="/user-management/edit-user" element={<RenderOnLogin><EditUser /></RenderOnLogin>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
