import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import axios from "axios";

const serverConfig = require("../config/server.js");

const Sidebar = ({token, handleLogout, handleSetIframeUrl, isDashboard}) => {

  const[readUserPrivileged, setReadUserPrivileged] = useState(false);
  const[loadDataPrivileged, setLoadDataPrivileged] = useState(false);
  const[viewDashboardPrivileged, setViewDashboardPrivileged] = useState(false);
  const[dashboardList, setDashboardList] = useState([]);
  const[toggleDashboardMenu, setToggleDashboardMenu] = useState(true);

  useEffect(() => {
    if(token !== null && token !== undefined){
      /* user_check_privilege - user_list */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:token, privilege:'user_list'})
      .then(response=>{
        console.log("user_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setReadUserPrivileged(false);
          } else if(response.data.is_privileged === 1){
            setReadUserPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
      /* user_check_privilege - load_data */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:token, privilege:'load_data'})
      .then(response=>{
        console.log("load_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setLoadDataPrivileged(false);
          } else if(response.data.is_privileged === 1){
            setLoadDataPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
      /* user_check_privilege - view_dashboard */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:token, privilege:'view_dashboard'})
      .then(response=>{
        console.log("view_dashboard privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setViewDashboardPrivileged(false);
          } else if(response.data.is_privileged === 1){
            setViewDashboardPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  useEffect(() => {
    if(isDashboard && viewDashboardPrivileged){
      console.log('isDashboard');
      /* dashboard_list_get */
      if(token !== null && token !== undefined){
        axios.post(serverConfig.api_base_url + "dashboard_list_get",{token:token})
        .then(response=>{
          console.log("dashboard_list_get response:", response);
          if(response.data.status === 200){
            if(response.data.items !== null){
              let res = JSON.parse(response.data.items);
              console.log(res);
              res.sort(function(a,b){
                return a.sequence_no - b.sequence_no;
              });
              setDashboardList(res);
              console.log(res[0].link);
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }
    } else {
      console.log('not isDashboard');
      setDashboardList([]);
    }
  }, [token, viewDashboardPrivileged]);

  const handleSetActive = (e) => {
    let el = document.querySelectorAll('#sidebar nav ul li > ul > li a');
    for(let i=0; i < el.length; i++){
      el[i].classList.remove('active');
      e.currentTarget.classList.add('active');
    }
  }

  return (
    <div id="sidebar">
      <nav>
        <ul>
          {readUserPrivileged && <li><NavLink to="/user-management"><span><img src={require('./../assets/images/user-management-icon.png')} alt="User Management" /></span>User Management</NavLink></li>}
          {loadDataPrivileged && <li><NavLink to="/data-ingestion-details"><span><img src={require('./../assets/images/upload-icon.png')} alt="Upload" /></span>Data Ingestion</NavLink></li>}
        {viewDashboardPrivileged && <li><NavLink exact="true" to="/" onClick={() => setToggleDashboardMenu(!toggleDashboardMenu)}><span><img src={require('./../assets/images/dashboard-icon.png')} alt="Dashboards" /></span>Dashboards</NavLink>
            {dashboardList.length > 0 && <ul className={toggleDashboardMenu ? 'd-block' : 'd-none'}>
                {dashboardList.map((item, index) => {
                  return(
                    <li key={index}><Link to="/" onClick={(e) => {handleSetIframeUrl(item.link, item.name); handleSetActive(e)}} /* className={index === 0 ? 'active' : undefined} */>{item.name}</Link></li>
                  )
                })}
              </ul>
            }
          </li>}
          {/* <li><NavLink to="/reports"><span><img src={require('./../assets/images/reports-icon.png')} alt="Reports" /></span>Reports</NavLink></li> */}
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;