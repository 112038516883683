import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import UserService from '../services/UserService';
import { useNavigate } from "react-router-dom";

const serverConfig = require("../config/server.js");

const EditUser = () => {

  const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
  const [actions, setActions] = useState(false);
  const [userPrivileged, setUserPrivileged] = useState(false);

  const [userData, setUserData] = useState({});

  const [rolesList, setRolesList] = useState([]);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  
  const [privilegeList, setPrivilegeList] = useState([]);

  const navigate = useNavigate();

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const handleActive = (emailid, changeActive) => {
    console.log(emailid);
    console.log(changeActive);

    axios.post(serverConfig.api_base_url + "user_active",{token: cToken, email_id: emailid, active: changeActive})
    .then(response=>{
      console.log('user_active response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user active is set 0/1
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const handleLocked = (emailid, changeLock) => {
    console.log(emailid);
    console.log(changeLock);

    axios.post(serverConfig.api_base_url + "user_locked",{token: cToken, email_id: emailid, locked: changeLock})
    .then(response=>{
      console.log('user_locked response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user is locked / unlocked
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleCheckboxChange = (event) => {
    console.log(event.target.checked, event.target.value);
    if(event.target.checked){
      axios.post(serverConfig.api_base_url + "user_grant_role",{token: cToken, email_id: userData.email_id, roles: [event.target.value]})
      .then(response=>{
        console.log('user_grant_role response', response);
        if(response.data.status === 200){
          console.log('success user_grant_role');
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    } else {
      axios.post(serverConfig.api_base_url + "user_revoke_role",{token: cToken, email_id: userData.email_id, roles: [event.target.value]})
      .then(response=>{
        console.log('user_revoke_role response', response);
        if(response.data.status === 200){
          console.log('success user_revoke_role');
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const [exPWMsg, setExPWMsg] = useState("");
  const [showExPWModal, setShowExPWModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserEmail, setCurrentUserEmail] = useState(null);
  const closeExPWModal = () => {
    setCurrentUser("");
    setCurrentUserEmail("");
    setExPWMsg("");
    setShowExPWModal(false);
  }
  const handleExPWModal = (username, useremail) => {
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setShowExPWModal(true);
  }
  const handleExpirePwd = () => {
    console.log({token: cToken, email_id: currentUserEmail});
    axios.post(serverConfig.api_base_url + "user_expire_password",{token: cToken, email_id: currentUserEmail})
    .then(response=>{
      console.log('user_expire_password response', response);
      if(response.data.status === 200){
        console.log('success');
        setChPWMsg("Successful");
        setTimeout(() => {
          closeChPWModal();
        }, 1000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  const [passwordSubmitDisabled, setPasswordSubmitDisabled] = useState(true);
  const [passwordChangeType, setPasswordChangeType] = useState(null);
  const [chPWMsg, setChPWMsg] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showChPWModal, setShowChPWModal] = useState(false);
  const closeChPWModal = () => {
    setCurrentUser("");
    setCurrentUserEmail("");
    setChPWMsg("");
    setNewPassword("");
    setPasswordSubmitDisabled(true);
    setShowChPWModal(false);
  }
  const handleChPWModal = (username, useremail) => {
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setShowChPWModal(true);
  }
  const handlePasswordGenerate = (event) => {
    //user_password_generate
    console.log(event.target.id);
    axios.post(serverConfig.api_base_url + "user_password_generate",{})
    .then(response=>{
      console.log('user_password_generate response', response);
      if(response.data.status === 200){
        setNewPassword(response.data.password);
        event.target.closest('.row').querySelector('input[type="password"]').type = "text";
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const togglePasswordVisibility = (event) => {
    //var x = document.getElementById("password");
    var x = event.target.closest('.position-relative').querySelector('input');
    var y = event.target;
    if (x.type === "password") {
      x.type = "text";
      y.classList.remove('fa-eye-slash');
      y.classList.add('fa-eye');
    } else {
      x.type = "password";
      y.classList.add('fa-eye-slash');
      y.classList.remove('fa-eye');
    }
  }
  const handlePasswordChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    console.log(name, value);

    if(event.target.id === "new_password") {
      if(event.target.checked){
        document.getElementById("password").disabled = false;
        document.getElementById("password").required = true;
        document.getElementById("password").closest(".row").classList.add('d-flex');
        document.getElementById("password").closest(".row").classList.remove('d-none');
      } else {
        document.getElementById("password").disabled = true;
        document.getElementById("password").required = false;
        document.getElementById("password").closest(".row").classList.add('d-none');
        document.getElementById("password").closest(".row").classList.remove('d-flex');
        document.getElementById("password").value = "";
        setNewPassword("");
      }
    }

    var checkboxes = document.querySelectorAll('input[name="changePassword"]');
    var checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
    if(checkedOne){
      setPasswordSubmitDisabled(false);
    } else {
      setPasswordSubmitDisabled(true);
    }

    if(document.querySelector('#change_password').checked && !document.querySelector('#new_password').checked){
      //only option 1 checked
      setPasswordChangeType("expire_password");
    } else if (!document.querySelector('#change_password').checked && document.querySelector('#new_password').checked){
      //only option 2 checked
      setPasswordChangeType("change_password_temp_0");
    } else if(document.querySelector('#change_password').checked && document.querySelector('#new_password').checked){
      //both option 1 and option 2 checked
      setPasswordChangeType("change_password_temp_1");
    } else if(!document.querySelector('#change_password').checked && !document.querySelector('#new_password').checked){
      //none
      setPasswordChangeType(null);
    }

    if(name === 'password'){
      setNewPassword(value);
    }
  }
  const handlePasswordChangeSubmit = (e) => {
    e.preventDefault();
    console.log(newPassword);
    console.log(passwordChangeType);
    if(passwordChangeType === "expire_password"){
      handleExpirePwd();
    } else if (passwordChangeType === "change_password_temp_0"){
      handleChangePwd(0);
    } else if (passwordChangeType === "change_password_temp_1"){
      handleChangePwd(1);
    }
  }
  const handleChangePwd = (tempVal) => {
    console.log({token: cToken, email_id: currentUserEmail, password: newPassword, temporary: tempVal});
    axios.post(serverConfig.api_base_url + "user_password_change ",{token: cToken, email_id: currentUserEmail, password: newPassword, temporary: tempVal})
    .then(response=>{
      console.log('user_password_change  response', response);
      if(response.data.status === 200){
        console.log('success');
        setChPWMsg("Successful");
        setTimeout(() => {
          closeChPWModal();
        }, 1000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const getToken = () => {
    //console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setCToken(response.data.token);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    //console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    }
  }, []);

  useEffect(() => {
    if(cToken !== null && cToken !== undefined){
      /* user_check_privilege - user_list */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:cToken, privilege:'user_list'})
      .then(response=>{
        console.log("user_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setUserPrivileged(false);
            navigate("/");
          } else if(response.data.is_privileged === 1){
            setUserPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [cToken]);

  useEffect(() => {
    //console.log('cToken', cToken);
    //console.log(sessionStorage.getItem('token'));
    //console.log('userPrivileged', userPrivileged);
    
    //if(cToken !== null && cToken !== undefined && userPrivileged){
    if(userPrivileged) {
      if(cToken !== null && cToken !== undefined){
        //console.log('cToken', cToken);

        /* user_get_list */
        axios.post(serverConfig.api_base_url + "user_get_list",{token:cToken})
        .then(response=>{
          console.log("user_get_list response:", response);
          //console.log(window.location.href.split('?')[1]);
          if(response.data.status === 200){
            let items = JSON.parse(response.data.items);
            console.log(items);
            if (items !== null || items !== undefined){
              var result = items.filter(user => user.email_id===window.location.href.split('?')[1]);
              console.log(result[0]);
              setUserData(result[0]);
              setCurrentUserRole(result[0].roles);
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));

        /* role_get_list */
        axios.post(serverConfig.api_base_url + "role_get_list",{token: cToken})
        .then(response=>{
          console.log('role_get_list response', JSON.parse(response.data.items));
          if(response.data.status === 200){
            setRolesList(JSON.parse(response.data.items));
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));

        /* privilege_get_list */
        axios.post(serverConfig.api_base_url + "privilege_get_list",{token: cToken})
        .then(response=>{
          console.log(response);
           console.log('privilege_get_list response', JSON.parse(response.data.items));
          if(response.data.status === 200){
            setPrivilegeList(JSON.parse(response.data.items));
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }
    }
  }, [cToken,actions, userPrivileged]);

  function allowDrop(ev) {
    ev.preventDefault();
    console.log('allowDrop');
  }
  
  function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
    console.log('drag');
  }
  
  function drop(ev) {
    console.log('drop', ev.target.id);
    var data = ev.dataTransfer.getData("text");
    if (document.getElementById(ev.target.id).classList.contains("no-drop")) {
      console.log("no transfer");
      ev.dataTransfer.effectAllowed = "link";
      ev.preventDefault();
    } else {
      ev.preventDefault();
      ev.target.appendChild(document.getElementById(data));
    }
  }

  return (<>
    <Header />
    <div className='main-wrapper'>
    <aside className="sidebar-component"><Sidebar token={cToken} handleLogout={handleLogout} /></aside>
      <main>
        <div className='content'>
          <div className='content-wrapper'>
            <Container fluid>
              <div className='page-title'>
                <h1>Edit User</h1>
                <Link to="/user-management">Back</Link>
              </div>
              <Form onSubmit={(e) => e.preventDefault()}>
                <section className='bg-gray mb-5'>
                  <fieldset>
                    <h4 className='txt-blue'>User Info.</h4>
                    <Row>
                      <Col>
                        <Form.Group controlId="name" className='input-wrap'>
                          <Form.Label><b>Name:</b></Form.Label>
                          <Form.Control type="text" name="name" defaultValue={userData.name} readOnly />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="email_id" className='input-wrap'>
                          <Form.Label><b>Email Id:</b></Form.Label>
                          <Form.Control type="email" name="email_id" defaultValue={userData.email_id} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </section>
                <section className='bg-gray mb-5'>
                  <fieldset>
                    <h4 className='txt-blue'>Status</h4>
                    <Row>
                      <Col sm="4">
                        <Form.Group controlId="name" className='input-wrap'>
                          <Form.Label><b>Active Status:</b></Form.Label>
                          {userData.active === 0 ? (<><button className='btn-icon me-2' title='Enable user' onClick={() => handleActive(userData.email_id, 1)}><img src={require('./../assets/images/disabled-icon.png')} alt="Disabled" /></button> Disabled</>) : (<><button className='btn-icon me-2' title='Disable user' onClick={() => handleActive(userData.email_id, 0)}><img src={require('./../assets/images/enabled-icon.png')} alt="Enabled" /></button> Enabled</>)}
                        </Form.Group>
                      </Col>
                      <Col sm="8">
                        <Form.Group controlId="email_id" className='input-wrap'>
                          <Form.Label><b>Lock Status:</b></Form.Label>
                          {userData.locked === 0 ? (<><button className='btn-icon me-2' title='Lock user' onClick={() => handleLocked(userData.email_id, 1)}><img src={require('./../assets/images/disabled-icon.png')} alt="Unlocked" /></button> Unlocked</>) : (<><button className='btn-icon me-2' title='Unlock user' onClick={() => handleLocked(userData.email_id, 0)}><img src={require('./../assets/images/enabled-icon.png')} alt="Locked" /></button> Locked</>)}
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </section>
                <section className='mb-5'>
                  <Row>
                    <Col sm="4">
                      <fieldset>
                        <div className='bg-gray'>
                          <h4 className='txt-blue'>Roles</h4>
                          <div className='bg-white'>
                            <div className='mb-3'><label><b>List of Roles</b></label></div>
                            <ul id="roles-list" className='simple-list'>
                              {rolesList.map((item, index) => (
                                <li key={index}><Form.Check type="checkbox" id={item} value={item} label={item} defaultChecked={currentUserRole && currentUserRole.includes(item)} onChange={handleCheckboxChange} /></li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </fieldset>
                    </Col>
                    {/* <Col sm="8">
                      <fieldset>
                        <div className='bg-gray'>
                          <h4><span className='txt-blue'>Privileges</span> <small>(drag & drop to grant and revoke privileges)</small></h4>
                          <Row>
                            <Col>
                              <div className='bg-white'>
                                <div className='mb-3'><label><b>List of Privileges</b></label></div>
                                <ul className='simple-list' id="list-of-privileges" onDrop={drop} onDragOver={allowDrop}>
                                  {privilegeList.map((item, index) => (
                                    <li key={index} id={'privilege-id-'+item.privilege_id} className="no-drop" draggable="true" onDragStart={drag}>{item.privilege_name}</li>
                                  ))}
                                </ul>
                              </div>
                            </Col>
                            <Col>
                              <div className='bg-white'>
                                <div className='mb-3'><label><b>Privileges Granted:</b></label></div>
                                <ul className='simple-list' id="privileges-granted" onDrop={drop} onDragOver={allowDrop}>
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </fieldset>
                    </Col> */}
                  </Row>
                </section>
                <section className='bg-gray'>
                  <fieldset>
                    <h4 className='txt-blue'>Password Management</h4>
                    <Row>
                      {/* <Col>
                        <div className='input-wrap'>
                          <Form.Label><b>Reset Password:</b></Form.Label>
                          <small className='txt-red me-3'>*click to auto generate new password</small>
                          <Button variant='transparent rounded'>Auto - Generate</Button>
                        </div>
                      </Col>
                      <Col>
                        <div className='input-wrap'>
                          <Form.Label><b>Expiry:</b></Form.Label>
                          <small className='txt-red me-3'>*password expires in 03 days</small>
                          <Button variant='transparent rounded'>Notify</Button>
                        </div>
                      </Col> */}
                      <Col>
                        <div className='input-wrap'>
                          <Form.Label><b>Password change:</b></Form.Label>
                          <small className='txt-red me-3'>*Force the user to change password on next login</small>
                          <Button variant='transparent rounded' onClick={() => handleChPWModal(userData.name, userData.email_id)}>Password change</Button>
                        </div>
                        {/* Expire Password */}
                        {/* <Modal show={showExPWModal} onHide={closeExPWModal} backdrop="static"keyboard={false} centered>
                          <Form onSubmit={handleExpirePwd}>
                            <Modal.Header closeButton>
                              <Modal.Title>Password change</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <p>Do you want to force the user ({currentUser}) to change password on next login?</p>
                              <p className="txt-green">{exPWMsg}</p>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="transparent" onClick={closeExPWModal}>No</Button>
                              <Button variant="primary" type="submit" className='ms-3'>Yes</Button>
                            </Modal.Footer>
                          </Form>
                        </Modal> */}

                        {/* Change Password */}
                        <Modal show={showChPWModal} onHide={closeChPWModal} backdrop="static"keyboard={false} centered>
                          <Form onSubmit={handlePasswordChangeSubmit}>
                            <Modal.Header closeButton>
                              <Modal.Title>Password change for {currentUser}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {/* <p>Change password for {currentUser}</p>
                              <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="New password" name="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                              </Form.Group>
                              <p className="txt-green">{chPWMsg}</p> */}
                              <Form.Group className='mb-3'>
                                <Form.Check name="changePassword" type="checkbox" id="change_password" label="User must change password on next login" value="User must change password on next login" className='mb-3' onChange={handlePasswordChange} />
                                <Form.Check name="changePassword" type="checkbox" id="new_password" label="Set a new password for the user" value="Set a new password for the user" className='mb-3' onChange={handlePasswordChange} />
                                <Row className='d-none'>
                                  <Col>
                                    <div className='position-relative'>
                                      <Form.Control type="password" id="password" name="password" placeholder="New password" disabled onChange={handlePasswordChange} value={newPassword} className='pe-4' />
                                      <span className='toggle-password' onClick={togglePasswordVisibility}><i className='fas fa-eye'></i></span>
                                    </div>
                                  </Col>
                                  <Col xs="auto"><Button id="changePwdGenerate" variant="transparent" onClick={handlePasswordGenerate} className='p-2'>Generate Password</Button></Col>
                                </Row>
                              </Form.Group>
                              <p className="txt-green">{chPWMsg}</p>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="transparent" onClick={closeChPWModal}>Cancel</Button>
                              <Button variant="primary" type="submit" className='ms-3' disabled={passwordSubmitDisabled}>Submit</Button>
                            </Modal.Footer>
                          </Form>
                        </Modal>
                      </Col>
                    </Row>
                  </fieldset>
                </section>
              </Form>
            </Container>
          </div>
        </div>
      </main>
    </div>
  </>);
}

export default EditUser;