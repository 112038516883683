import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import axios from "axios";
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");

const Dashboard = () => {

  const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
  const [iframeUrl, setIframeUrl] = useState('');
  const [dashboardName, setDashboardName] = useState("Welcome to Coldstar");
  const [viewDashboardPrivileged, setViewDashboardPrivileged] = useState(false);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setCToken(response.data.token);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setCToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(cToken !== null && cToken !== undefined){
          /* user_check_privilege - view_dashboard */
          axios.post(serverConfig.api_base_url + "user_check_privilege",{token:cToken, privilege:'view_dashboard'})
          .then(response=>{
            console.log("view_dashboard privilege response:", response);
            if(response.data.status === 200){
              if(response.data.is_privileged === 0){
                setViewDashboardPrivileged(false);
              } else if(response.data.is_privileged === 1){
                setViewDashboardPrivileged(true);
              }
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));
        }
      }, [cToken]);

  useEffect(() => {
    if(cToken !== null && cToken !== undefined){
      if(iframeUrl === '' && viewDashboardPrivileged){
        axios.post(serverConfig.api_base_url + "dashboard_list_get",{token:cToken})
        .then(response=>{
          console.log("dashboard_list_get response:", response);
          if(response.data.status === 200){
            if(response.data.items !== null){
              let res = JSON.parse(response.data.items);
              console.log(res);
              res.sort(function(a,b){
                return a.sequence_no - b.sequence_no;
              });
              console.log(res[0]);
              //setIframeUrl(res[0].link);
              //setDashboardName(res[0].name);
              setDashboardName("Welcome to Coldstar");
              setIframeUrl("https://coldstarreports.dbtez.com/superset/welcome/");
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }
    }
  }, [cToken, viewDashboardPrivileged]);

  const handleIframeUrl = (url, name) => {
    console.log(url +' '+ name);
    setDashboardName(name);
    setIframeUrl(url);
  }

  return (<>
    <Header />
    <div className='main-wrapper'>
      <aside className="sidebar-component"><Sidebar token={cToken} handleLogout={handleLogout} handleSetIframeUrl={handleIframeUrl} isDashboard /></aside>
      <main id="dashboard">
        <div className='content'>
          <div className='content-wrapper'>
            <Container fluid>
              <div className='page-title'><h1>{dashboardName && dashboardName}</h1></div>
              <div className={dashboardName === 'Welcome to Coldstar' ? 'iframe-wrapper d-none' : 'iframe-wrapper'}>
                {iframeUrl && <iframe
                  width="100%"
                  height="700"
                  seamless
                  frameBorder="0"
                  scrolling="yes"
                  src={iframeUrl}
                  >
                </iframe>}
              </div>
            </Container>
          </div>
        </div>
      </main>
    </div>
  </>);
}

export default Dashboard;
