import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import axios from "axios";
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");

const Reports = () => {

  const [cToken, setCToken] = useState(sessionStorage.getItem('token'));

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setCToken(response.data.token);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setCToken(sessionStorage.getItem('token'));
    }
  }, []);

  return (<>
    <Header />
    <div className='main-wrapper'>
      <aside className="sidebar-component"><Sidebar token={cToken} handleLogout={handleLogout} /></aside>
      <main>
        <div className='content'>
          <div className='content-wrapper'>
            <Container fluid>
              <h1>Reports</h1>
            </Container>
          </div>
        </div>
      </main>
    </div>
  </>);
}

export default Reports;