import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import UserService from '../services/UserService';
import { useNavigate } from "react-router-dom";

const serverConfig = require("../config/server.js");

const InviteUser = () => {

  const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
  const [userPrivileged, setUserPrivileged] = useState(false);

  const [userData, setUserData] = useState({});

  const [rolesList, setRolesList] = useState([]);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  
  const [privilegeList, setPrivilegeList] = useState([]);

  const navigate = useNavigate();

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const handleCheckboxChange = (event) => {
    console.log(event.target.checked, event.target.value);
    
  }

  const [resetDisabled, setResetDisabled] = useState(true);
  const [inviteDisabled, setInviteDisabled] = useState(true);
  const [rolesDisabled, setRolesDisabled] = useState(true);
  const [privilegesDisabled, setPrivilegesDisabled] = useState(true);
  const [passwordDisabled, setPasswordDisabled] = useState(true);
  const [passwordFieldRequired, setPasswordFieldRequired] = useState(false);

  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    console.log(name, value);

    const newData={...inputs}
    
    if(name === 'roles'){
      let el = document.querySelectorAll('input[name="roles"]');
      let i;
      if(document.querySelector('input[name="roles"]:checked')){
        console.log('checked');
        for(i=0; i < el.length; i++){
          console.log(el[i].value);
          el[i].required = false;
        }
      } else {
        console.log('not checked');
        for(i=0; i < el.length; i++){
          console.log(el[i].value);
          el[i].required = true;
        }
      }
    }

    if(name === "passwordType"){
      if(value === 'set-password'){
        setPasswordFieldRequired(true);
      } else {
        setPasswordFieldRequired(false);
        newData['password_input'] = '';
      }
    }

    if(event.target.type === 'checkbox'){
      var checkboxes = document.getElementsByName(name);
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
      newData[name]=checkboxesChecked;
    } else if(event.target.type === 'radio'){
      //do nothing
    } else {
      newData[name]=value;
    }

    console.log(newData);
    setInputs(newData);
    console.log('inputs', inputs);

    //setInputs(values => ({...values, [name]: value}));
    setInputs((stateInputs) => {
      console.log(stateInputs);
      //console.log(document.getElementById('name').value, document.getElementById('email_id').value);
      if(stateInputs.first_name !== '' && stateInputs.first_name !== undefined && stateInputs.last_name !== '' && stateInputs.last_name !== undefined && stateInputs.email_id !== '' && stateInputs.email_id !== undefined) {
        //console.log('1');
        setRolesDisabled(false);
      } else {
        //console.log('2');
        setRolesDisabled(true);
        setPasswordDisabled(true);
        setInviteDisabled(true);
      }
      //console.log(stateInputs.roles);
      if(stateInputs.first_name !== '' && stateInputs.first_name !== undefined && stateInputs.last_name !== '' && stateInputs.last_name !== undefined && stateInputs.email_id !== '' && stateInputs.email_id !== undefined && stateInputs.roles !== undefined) {
        /* setPrivilegesDisabled(false);
      } else {
        setPrivilegesDisabled(true);
      } */
        if(stateInputs.roles.length > 0){
          setPasswordDisabled(false);
          if(stateInputs.password_input){
            setInviteDisabled(false);
          } else {
            setInviteDisabled(true);
          }
        } else {
          setPasswordDisabled(true);
          setInviteDisabled(true);
        }
      }
      return stateInputs;
    });
    
    if(document.getElementById('first_name').value !== '' || document.getElementById('last_name').value !== '' || document.getElementById('email_id').value !== '') {
      //console.log('3');
      setResetDisabled(false);
    } else if(document.getElementById('first_name').value === '' || document.getElementById('last_name').value === '' || document.getElementById('email_id').value === '') {
      //console.log('4');
      setResetDisabled(true);
    }
  }

  const [showUserCreatedModal, setShowUserCreatedModal] = useState(false);
  const closeUserCreatedModal = () => {
    setShowUserCreatedModal(false);
  }

  const submitInviteUser = (event) => {
    event.preventDefault();
    console.log(inputs);

    var formdata = {
      token:cToken,
      email_id:inputs.email_id,
      first_name:inputs.first_name,
      last_name:inputs.last_name,
      password_input:inputs.password_input
    }
    axios.post(serverConfig.api_base_url + "user_create",formdata)
    .then(response=>{
      console.log("User create response:", response);
      if(response.data.status === 200){
        //user_grant_role - start
        console.log(inputs.roles, inputs.email_id);
        axios.post(serverConfig.api_base_url + "user_grant_role",{token: cToken, email_id: inputs.email_id, roles: inputs.roles})
        .then(response=>{
          console.log('user_grant_role response', response);
          if(response.data.status === 200){
            console.log('success user_grant_role');
            setShowUserCreatedModal(true);
            handleReset();
            setTimeout(() => {
              setShowUserCreatedModal(false);
            }, 3000);
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
        //user_grant_role - end
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleReset = () => {
    document.getElementById("formInviteUser").reset();
    setInputs({});
    setResetDisabled(true);
    setInviteDisabled(true);
    setRolesDisabled(true);
    setPasswordDisabled(true);
    setPrivilegesDisabled(true);
  }
  
  const handleNext = () => {
    setPasswordDisabled(false);
    document.querySelector('#auto-generate').checked = true;
  }

  const getToken = () => {
    //console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setCToken(response.data.token);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    //console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    }
  }, []);

  useEffect(() => {
    if(cToken !== null && cToken !== undefined){
      /* user_check_privilege - user_create */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:cToken, privilege:'user_create'})
      .then(response=>{
        console.log("user_create privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setUserPrivileged(false);
            navigate("/");
          } else if(response.data.is_privileged === 1){
            setUserPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [cToken]);

  useEffect(() => {
    //console.log('cToken', cToken);
    //console.log(sessionStorage.getItem('token'));
    //console.log('userPrivileged', userPrivileged);
    
    //if(cToken !== null && cToken !== undefined && userPrivileged){
    if(userPrivileged) {
      if(cToken !== null && cToken !== undefined){
        //console.log('cToken', cToken);

        /* role_get_list */
        axios.post(serverConfig.api_base_url + "role_get_list",{token: cToken})
        .then(response=>{
          console.log('role_get_list response', response);
          console.log('role_get_list response items', JSON.parse(response.data.items));
          if(response.data.status === 200){
            setRolesList(JSON.parse(response.data.items));
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));

        /* privilege_get_list */
        axios.post(serverConfig.api_base_url + "privilege_get_list",{token: cToken})
        .then(response=>{
          console.log(response);
           console.log('privilege_get_list response', response.data.items);
          if(response.data.status === 200){
            setPrivilegeList(response.data.items);
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }
    }
  }, [cToken, userPrivileged]);

  function allowDrop(ev) {
    ev.preventDefault();
  }
  
  function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
    console.log(ev.target.id);
  }
  
  function drop(ev) {
    console.log(ev.target.id);
    var data = ev.dataTransfer.getData("text");
    if (document.getElementById(ev.target.id).classList.contains("no-drop")) {
      console.log("no transfer");
      ev.dataTransfer.effectAllowed = "link";
      ev.preventDefault();
    } else {
      ev.preventDefault();
      ev.target.appendChild(document.getElementById(data));
    }
  }

  const handlePasswordGenerate = (event) => {
    //user_password_generate
    console.log(event.target.id);
    axios.post(serverConfig.api_base_url + "user_password_generate",{})
    .then(response=>{
      console.log('user_password_generate response', response);
      if(response.data.status === 200){
        setInputs({
          ...inputs,
          password_input:response.data.password
        });
        event.target.closest('.row').querySelector('input[type="password"]').type = "text";
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const togglePasswordVisibility = (event) => {
    //var x = document.getElementById("password");
    var x = event.target.closest('.position-relative').querySelector('input');
    var y = event.target;
    if (x.type === "password") {
      x.type = "text";
      y.classList.remove('fa-eye-slash');
      y.classList.add('fa-eye');
    } else {
      x.type = "password";
      y.classList.add('fa-eye-slash');
      y.classList.remove('fa-eye');
    }
  }

  return (<>
    <Header />
    <div className='main-wrapper'>
      <aside className="sidebar-component"><Sidebar token={cToken} handleLogout={handleLogout} /></aside>
      <main>
        <div className='content'>
          <div className='content-wrapper'>
            <Container fluid>
              <div className='page-title'>
                <h1>Create User</h1>
                <Link to="/user-management">Back</Link>
              </div>
              <Form onSubmit={submitInviteUser} id="formInviteUser">
                <section className='bg-gray mb-3'>
                  <fieldset>
                    <h4 className='txt-blue'>User Info.</h4>
                    <Row>
                      <Col>
                        <Form.Group controlId="first_name" className='input-wrap'>
                          <Form.Label><b>First Name:</b><span className='txt-red'>*</span></Form.Label>
                          <Form.Control type="text" name="first_name" value={inputs.first_name || ""} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="last_name" className='input-wrap'>
                          <Form.Label><b>Last Name:</b><span className='txt-red'>*</span></Form.Label>
                          <Form.Control type="text" name="last_name" value={inputs.last_name || ""} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="email_id" className='input-wrap'>
                          <Form.Label><b>Email Id:</b><span className='txt-red'>*</span></Form.Label>
                          <Form.Control type="email" name="email_id" value={inputs.email_id || ""} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </section>
                <section className='mb-3'>
                  <Row>
                    <Col sm="4">
                      <fieldset /* disabled={rolesDisabled} */>
                        <div className='bg-gray'>
                          <h4 className='txt-blue'>Roles</h4>
                          <div className='bg-white'>
                            <div className='mb-3'><label><b>List of Roles</b><span className='txt-red'>*</span></label></div>
                            {/* <ul id="roles-list" className='simple-list'>
                              {rolesList.map((item, index) => (
                                <li key={index}><Form.Check type="checkbox" id={item} value={item} label={item} onChange={handleCheckboxChange} /></li>
                              ))}
                            </ul> */}
                            <ul id="roles-list" className='simple-list'>
                              {rolesList.map((item, index) => (
                                <li key={index}>
                                  <Form.Check type="checkbox" name="roles" value={item} onChange={handleChange} id={item} label={item} defaultChecked="" required />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </fieldset>
                    </Col>
                    {/* <Col sm="8">
                      <fieldset disabled={privilegesDisabled}>
                        <div className='bg-gray'>
                          <div className='d-flex justify-content-between'>
                            <h4><span className='txt-blue'>Privileges</span> <small>(drag & drop to grant and revoke privileges)</small></h4>
                            <Button variant='text' onClick={handleNext} className='mb-3'><small>Next</small></Button>
                          </div>
                          <Row>
                            <Col>
                              <div className='bg-white'>
                                <div className='mb-3'><label><b>List of Privileges</b></label></div>
                                <ul className='simple-list' id="list-of-privileges" onDrop={drop} onDragOver={allowDrop}>
                                  {privilegeList.map((item, index) => (
                                    <li key={index} id={'privilege-id-'+item.privilege_id} className="no-drop" draggable="true" onDragStart={drag}>{item.privilege_name}</li>
                                  ))}
                                </ul>
                              </div>
                            </Col>
                            <Col>
                              <div className='bg-white'>
                                <div className='mb-3'><label><b>Privileges Granted:</b></label></div>
                                <ul className='simple-list' id="privileges-granted" onDrop={drop} onDragOver={allowDrop}>
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </fieldset>
                    </Col> */}
                  </Row>
                </section>
                <section className='bg-gray mb-3'>
                  <fieldset /* disabled={passwordDisabled} */>
                    <h4 className='txt-blue'>Password<span className='txt-red'>*</span></h4>
                    {/* <Form.Check type="radio" id="auto-generate" label="Auto generate password and send to user's email id" name="passwordType" value="auto-generate" onChange={handleChange} required />
                    <Row className='mt-3 align-items-center'>
                      <Col xs="auto">
                        <Form.Check type="radio" id="set-password" label="Enter password for the user :" name="passwordType" value="set-password" onChange={handleChange} />
                      </Col>
                      <Col xs="3">
                        <Form.Control type="text" name="password_input" value={inputs.password_input || ""} onChange={handleChange} disabled={!passwordFieldRequired} required={passwordFieldRequired} />
                      </Col>
                    </Row> */}
                    <Row className='align-items-center'>
                      <Col>
                        <div className='position-relative'>
                          <Form.Control type="password" placeholder="Password" name="password_input" value={inputs.password_input || ""} onChange={handleChange} required className='pe-5' />
                          <span className='toggle-password' onClick={togglePasswordVisibility}><i className='fas fa-eye'></i></span>
                        </div>
                      </Col>
                      <Col xs="auto"><Button id="createUserPwdGenerate" variant="transparent" onClick={handlePasswordGenerate} className='p-2'>Generate Password</Button></Col>
                    </Row>
                  </fieldset>
                </section>
                <section>
                  <Row>
                    <Col>
                      <Row>
                        <Col><Button variant="main" className='btn-full' type="submit" /* disabled={inviteDisabled} */>CREATE</Button></Col>
                        <Col><Button variant="transparent" className='btn-full' type="button" /* disabled={resetDisabled} */ onClick={handleReset}>RESET</Button></Col>
                      </Row>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Modal show={showUserCreatedModal} onHide={closeUserCreatedModal} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="txt-green text-center">User created successfully</p>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>
                </section>
              </Form>
            </Container>
          </div>
        </div>
      </main>
    </div>
  </>);
}

export default InviteUser;